import * as React from "react"

export function SupportSvg<T>(props: T) {
    return (
        <svg viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path
                d="M1384.344 1106.815c-7.06 0-12.783 5.723-12.783 12.783 0 3.2 1.175 6.123 3.118 8.366a10.398 10.398 0 016.78-6.136 5.47 5.47 0 01-2.615-4.665c0-3.028 2.465-5.478 5.5-5.478s5.5 2.45 5.5 5.478a5.47 5.47 0 01-2.615 4.665 10.4 10.4 0 016.78 6.136 12.73 12.73 0 003.117-8.366c0-7.06-5.723-12.783-12.782-12.783m8.724 22.125a9.171 9.171 0 00-8.724-6.299 9.174 9.174 0 00-8.725 6.3 12.74 12.74 0 008.725 3.44c3.373 0 6.44-1.307 8.724-3.44m-22.724-9.343c0-7.732 6.267-14 14-14s14 6.268 14 14a13.96 13.96 0 01-4.156 9.955 13.957 13.957 0 01-9.844 4.045 13.955 13.955 0 01-9.844-4.045 13.96 13.96 0 01-4.156-9.955m14-6.696c-2.368 0-4.283 1.91-4.283 4.261 0 2.35 1.915 4.261 4.283 4.261 2.368 0 4.283-1.91 4.283-4.26 0-2.352-1.915-4.262-4.283-4.262"
                transform="translate(-1300 -3797) translate(-69.844 2691.86)"
                fill="#837E91"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function CrossIcon<T>(props:T) {
    return (
        <svg
            width="17px"
            height="18px"
            viewBox="0 0 17 18"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M531.831 90.904a.916.916 0 011.293 0l6.658 6.66 6.658-6.66a.916.916 0 011.293 0 .916.916 0 010 1.293l-6.66 6.658 6.66 6.658a.916.916 0 010 1.293.916.916 0 01-1.293 0l-6.658-6.66-6.658 6.66a.916.916 0 01-1.293 0 .916.916 0 010-1.293l6.66-6.658-6.66-6.658a.916.916 0 010-1.293"
                transform="translate(-531 -90)"
                fill="#FFF"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}