import { useEffect, useState } from "react";
import { TelescopeIcon } from "./InscriptionChecker.icons";
import { fetchGetInscription, fetchLastViewed } from "../../api/apiRequests";
import { useData } from "../../utils/useData";
import { TJson, TLastViewed } from "../../types/apiTypes";
import { setField } from "../../redux/slice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnack } from "../snack/useSnack";

const initialFields: TJson = {
  linked: "",
  signature: "",
  attributes: "",
  token_id: "",
};
//
const initialConstantFields: TJson = {
  id: "",
  address: "",
  sat: "",
  timestamp: "",
  genesis_height: "",
  genesis_fee: "",
  genesis_transaction: "",
  offset: "",
};
export const useInscriptionChecker = () => {
  const [fields, setFields] = useState<TJson>(initialFields);
  const [constantFields, setConstantFields] = useState<TJson>({});
  const [pic, setPic] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [inscriptionInput, setInscriptionInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userId, inscriptionId } = useData();
  const [list, setList] = useState<TLastViewed[]>([]);
  const snack = useSnack();

  const params = useParams();
  // console.log(params);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setInscriptionInput(params.id);
      getInscription(params.id);
    } else if (inscriptionId) {
      setInscriptionInput(inscriptionId);
      getInscription(inscriptionId);
    } else {
      const id = location.pathname.split("/")[2];
      dispatch(setField({ inscriptionId: id }));
      setInscriptionInput(id);
      getInscription(id);
    }
    getLastViewed();
  }, []);

  const show = async () => {
    await getInscription(inscriptionInput);
    await getLastViewed();
  };
  const getInscription = async (inscriptionId: string) => {
    if (!inscriptionId) return;
    setIsLoading(true);
    try {
      const res = await fetchGetInscription({
        uuid: userId,
        inscription_id: inscriptionId,
      });
      if (res) {
        // const metadata: TJson = Object.entries(res.metadata).map(
        //   ([key, value]) => ({
        //     [key.split("_").join(" ")]: value,
        //   })
        // );

        setFields(res.metadata);
        setConstantFields({
          id: res.inscription_id,
          address: res.owner_address,
          sat: res.sat,
          timestamp: res.timestamp,
          genesis_height: res.genesis_height,
          genesis_fee: res.genesis_fee,
          genesis_transaction: res.tx,
          offset: res.offset,
        });
        setPic(res.inscription_id);
        setName(res.inscription_name);
      }
    } catch (err) {
      snack("Could not find an ordinal with provided id! Please check your input!", "error");
    }
    setIsLoading(false);
  };

  const getLastViewed = async () => {
    const res = await fetchLastViewed({ uuid: userId });
    res && setList(res);
  };

  const chooseLastViewed = async (id: string) => {
    navigate(`/explorer/${id}`);
    dispatch(setField({ inscriptionId: id }));
    setInscriptionInput(id);
    await getInscription(id);
  };

  return {
    fields,
    pic,
    isLoading,
    setInscriptionInput,
    inscriptionInput,
    show,
    name,
    list,
    chooseLastViewed,
    constantFields,
  };
};
