import {Box, IconButton, styled} from "@mui/material";
import {FlexBox, SectionContainer} from "../../styles/basicLayout";
import {SubscribeForm} from "../transfer/Transfer.styles";
import {mobileScreen} from "../../styles/breakpoints";
import {LoadingButton} from "@mui/lab";


export const SearchButton = styled(LoadingButton)`
  padding: 1.5rem !important;
  
  > svg, p{
    transition: .3s;
  }
  
  &.MuiLoadingButton-loading{
    
    > svg, > p{
      opacity: 0;
    }
    
    .MuiLoadingButton-loadingIndicator{
      color: #fff;
      > span{
        width: 3rem !important;
        height: 3rem !important;
      }
    }
  }
  
  > p{
    display: none;
  }
  
  ${mobileScreen}{
    > svg{
      display: none;
    }
    
    > p{
      padding: 1rem;
      display: block;
      font-size: 3rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.28px;
      color: #fff;
    }
  }
  
`

export const SearchCard = styled(FlexBox)`
  border-radius: 2rem;
  box-shadow: 0 .8rem 2.6rem 0 rgba(0, 0, 0, 0.17);
  padding: .5rem;
  justify-content: flex-start;
  cursor: pointer;
  
  h3{
    font-size: 3rem;
    line-height: 1.167;
    letter-spacing: 0em;
    font-family: "Hellix",sans-serif;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #7239b5;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  
  > div:first-of-type{
    flex: 1 0 50%;
    align-self: flex-start;
  }
  
  > div:last-of-type{
    padding: 2rem 4rem;
    flex: 1 1 50%;


    h4,p{
      font-size: 1.56rem;
      word-break: break-all;
      hyphens: auto;
      
      ${mobileScreen}{
        font-size: 2rem;
      }
    }
  }
`

export const UpdatesBackground = styled(Box)`
  background-color: #d3cfd6;
  padding: 10rem 0;
  

  

`

export const SearchContainer = styled(SectionContainer)`
  h4{
    font-size: 2.7rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #524959;
    margin-bottom: 2rem;
  }

  p{
    font-size: 2.1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: #524959;
    opacity: 0.77;
    margin-bottom: 3.4rem;
  }
`

export const SearchSubscribe = styled(SubscribeForm)`
  width: 100%;
  margin-bottom: 0;
    > div{
      width: 100%;
    }
  
  .MuiInputBase-root{
    box-shadow: none;
    border-radius: 1.9rem;
    background-color: #fff;
  }
  
  .MuiButton-root{
    border-radius: 1.9rem;
    background-color: #7d658f;
    background-image: none;
  }
`