import { Box, FormControl, Stack, styled, Tooltip, Typography } from "@mui/material";
import { FlexBox } from "../../styles/basicLayout";
import {mobileScreen, mobileSmallScreen} from "../../styles/breakpoints";

export const TransferBackground = styled(Box)`
  background-image: linear-gradient(322deg, #3a1a7e, #12033d);
  margin-top: -10rem;
  padding-top: 15rem;
`;

export const TransferActions = styled(Stack)`
  h3 {
    font-family: "Hellix-Bold", "Hellix", sans-serif;
    font-size: 4.23rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    letter-spacing: 0.47px;
    color: #fff;
    margin-bottom: 4.7rem;
  }
`;

export const GasFeeButtons = styled(FlexBox)`
  gap: 3rem;
  margin-bottom: 8.8rem;

  ${mobileScreen} {
    flex-direction: column;
  }

  .MuiButtonBase-root {
    width: 100%;
    flex-direction: column;
    background-image: linear-gradient(213deg, #200c53, #0e0529),
      linear-gradient(to bottom, #663fc9, #31156f);
    border: 1px solid #31156f;
    border-radius: 2.2rem;
    padding: 5rem;
    box-shadow: 0 10px 10px 0 rgba(14, 5, 41, 0.23);
    transition: 0.3s;

    ${mobileScreen} {
      flex-direction: row;
    }

    &.active {
      background-color: #3a1a7e;
      box-shadow: none;
      background-image: none;
      border: none;

      > p:last-of-type {
        color: #9370e1;
      }
    }

    > p {
      font-stretch: normal;
      font-style: normal;
      text-transform: none;
      &:first-of-type {
        font-size: 2.91rem;
        font-weight: normal;
        line-height: 1.16;
        letter-spacing: 0.32px;
        text-align: center;
        color: #fff;
        opacity: 0.64;
        margin-bottom: 1.1rem;

        ${mobileScreen} {
          margin-bottom: 0;
          margin-right: 1.2rem;
        }
      }

      &:last-of-type {
        font-family: "Hellix-Bold", "Hellix", sans-serif;
        font-size: 3.61rem;
        font-weight: bold;
        line-height: 0.94;
        letter-spacing: 0.4px;
        text-align: center;
        color: #5027ab;
      }
    }
  }
`;

export const TransferCalculations = styled(Stack)`
  text-align: left;
  row-gap: 1.8rem;
  margin-bottom: 12.5rem;

  ${mobileScreen} {
    text-align: center;
  }

  p {
    font-family: "Hellix-Medium", "Hellix", sans-serif;
    font-size: 3.21rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: 0.36px;
    color: #868495;

    ${mobileScreen} {
      &:not(:last-of-type) {
        margin-bottom: 5rem;
      }
    }

    > span {
      font-weight: bold;
      color: #9370e1;

      ${mobileScreen} {
        margin-top: 1.2rem;
        display: block;
      }
    }
  }
`;

export const TransferActionButtons = styled(FlexBox)`
  margin-bottom: 18rem;

  ${mobileScreen} {
    flex-direction: column;
    row-gap: 3.6rem;
  }
  
  p{
    text-transform: uppercase;
  }

  .MuiButtonBase-root {
    font-family: "Hellix-SemiBold", "Hellix", sans-serif;
    font-size: 2.7rem;
    font-weight: 600;
    font-stretch: normal;
    text-transform: none;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;

    border-radius: 5rem;
    padding: 3rem;
    box-shadow: 0 11px 9px 0 rgba(14, 5, 41, 0.12);
    background-image: linear-gradient(244deg, #5633f6, #753ab1 26%);

    ${mobileScreen} {
      width: 100%;
      font-size: 3.5rem;
      padding: 3rem 3rem;
    }

    ${mobileSmallScreen} {
      font-size: 3rem;
    }


  }

  .download {
    position: relative;

    .MuiButtonBase-root{
      color: #4d31cb;
      background-color: #ebe9e9;
      background-image: none;
    }

  }

  > p {
    font-family: "Hellix-Bold", "Hellix", sans-serif;
    font-size: 2.37rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    text-align: center;
    color: #fff;
  }
`;

export const TransferDownloadButtonTips = styled(FlexBox)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-10%, -50%);

  > p {
    border-radius: 2.43rem;
    border: solid 1.7px #fff;
    background-color: #200c53;
    padding: 0rem 2.5rem;
    font-family: "Holtwood One SC", sans-serif;

    font-size: 2.57rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.29px;
    text-align: center;
    color: #fff;
    margin-right: 0.9rem;
    cursor: default;
  }

  > .MuiBox-root {
    border: solid 2.7px #361878;
    background-color: #fff;
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    min-width: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mobileScreen}{
      display: none;
    }
    > i {
      font-family: "Hellix-Bold", "Hellix", sans-serif;
      font-size: 3.21rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      text-transform: none;
      line-height: 1.05;
      letter-spacing: 0.36px;
      text-align: center;
      color: #2c1166;
      cursor: default;

      &:hover {
        background-color: #fff;
      }
    }
  }
  

`;

export const TransferCheckStatus = styled(Stack)`
  margin-top: 10.8rem;
  h3 {
    font-family: "Hellix-Bold", "Hellix", sans-serif;
    font-size: 4.23rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    letter-spacing: 0.47px;
    color: #fff;
    margin-bottom: 4.7rem;

    ${mobileScreen} {
      text-align: center;
    }
  }
  
  h4, a {
    align-self: flex-start;
    text-transform: capitalize;
  }
  h4{
    font-weight: bold;
    color: #9370e1;
    font-size: 3.8rem;
    margin-right: 2rem;
    // ${mobileSmallScreen} {
    //   margin-top: -4rem;
    //   width: 100%;
    //   text-align: center;
    //   margin-bottom: 3rem;
    // }
  }
  
  a {
    font-weight: 500;
    color: #FFFFFF;
    align-self: flex-end;
    margin-bottom: 0.8rem;
  }
`;

export const SubscribeForm = styled(FormControl)`
  margin: 5rem 0 14.6rem;
  label {
    font-family: "Hellix-Medium", "Hellix", sans-serif;
    font-size: 2.61rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.29;
    letter-spacing: 0.29px;
    color: #dfdded;
    margin-bottom: 2rem;
    ${mobileScreen} {
      text-align: center;
    }
  }

  .MuiInputBase-root {
    margin-right: 2.5rem;
    margin-bottom: 0;
    flex: 1 0 auto;
    ${mobileScreen} {
      width: 100%;
      margin-right: 0;
      margin-bottom: 4.5rem;
    }
  }

  .MuiButtonBase-root {
    //height: 100%;
    padding: 1.5rem 4.3rem;
    background-image: linear-gradient(238deg, #5633f6, #753ab1 25%);
    box-shadow: 0 1.1rem .9rem 0 rgba(14, 5, 41, 0.12);
    //flex: 1 0 fit-content;
    border-radius: 1.5rem;

    font-size: 2.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    line-height: 1.41;
    letter-spacing: 0.27px;
    color: #dfdded;

    ${mobileScreen} {
      width: 100%;
      padding: 2rem 4.3rem;
      font-size: 2.8rem;
    }
  }

  > div {
    ${mobileScreen} {
      flex-direction: column;
    }
  }
`;

export const StatusTitle = styled(Typography)`

`
