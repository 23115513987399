import {useCallback} from "react";
import {addErrorSnack, addInfoSnack, addSuccessfulSnack, TSnack} from "../../redux/snack/snackSlice";
import {useDispatch} from "react-redux";


export const useSnack = () => {
    const dispatch = useDispatch();
    const snack : { [key in TSnack]: (message: string) => void } = {
        info: (message: string) => dispatch(addInfoSnack(message)),
        error: (message: string) => dispatch(addErrorSnack(message) as any),
        success: (message: string) => dispatch(addSuccessfulSnack(message)),
    }
    return useCallback((message: string, variant: TSnack = 'info') => snack[variant](message)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);
}
