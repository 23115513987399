import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `"Hellix", sans-serif`,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        html: {
          fontSize: 10,
          [theme.breakpoints.down("xl")]: {
            fontSize: 9,
          },
          [theme.breakpoints.down("lg")]: {
            fontSize: 7,
          },
          [theme.breakpoints.down("md")]: {
            fontSize: 6,
          },
        },
        body: {
          // backgroundColor: "#0e0529",
          overflowX: 'hidden'
        },
        a: {
          textDecoration: 'none'
        }
      }),
    },
  },
});

export default theme;
