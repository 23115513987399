import Create from "../components/create/Create";
import Transfer from "../components/transfer/Transfer";
import Faq from "../components/faq/FAQ";
import React from "react";

export const MainPage = () => {
    return (
        <>
            <Create/>
            <Transfer/>
            <Faq/>
        </>
    )
}