//Core
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
//Styles
import { LastViewContainer, LastViewTitle, SlideBox } from "./LastView.styles";
import { SectionContainer } from "../../styles/basicLayout";
//Images
import Picture1 from "../../assets/webp/Picture1.webp";
import Picture2 from "../../assets/webp/Picture2.webp";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const LastView = () => {
  return null

  return (
    <LastViewContainer>
      <SectionContainer>
        <LastViewTitle>{"Last viewed:"}</LastViewTitle>
      </SectionContainer>
      <Swiper
        centeredSlides
        loop
        spaceBetween={15}
        // slidesPerView={6}
      >
        <SwiperSlide>
          <SlideBox>
            <img src={Picture2} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>
        <SwiperSlide>
          <SlideBox>
            <img src={Picture1} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>
        <SwiperSlide>
          <SlideBox>
            <img src={Picture2} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>
        <SwiperSlide>
          <SlideBox>
            <img src={Picture1} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>
        <SwiperSlide>
          <SlideBox>
            <img src={Picture2} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <SlideBox>
            <img src={Picture1} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <SlideBox>
            <img src={Picture1} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <SlideBox>
            <img src={Picture1} alt={"nft"} />
          </SlideBox>
        </SwiperSlide>
      </Swiper>
    </LastViewContainer>
  );
};
