import {Box, IconButton, styled} from "@mui/material";
import {mobileSmallScreen} from "./breakpoints";

export const SectionContainer = styled(Box)`
  width: 80%;
  max-width: 104rem;
  margin: 0 auto;
  
  &.wide{
    //max-width: 135rem;
  }
  
  ${mobileSmallScreen}{
    width: 90%;
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionDivider = styled(Box)`
width: 100vw;
  height: 1px;
  background-color: #fff;
  opacity: .35;
`

export const ImgBox = styled(Box)`
  display: flex;
  img, svg {
    width: 100%;
    height: 100%;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  
  svg{
    path{
      fill: #0e0529;
    }
  }
`