import {useLocation} from "react-router-dom";

export const usePage = () => {
  const location = useLocation()

  const mainPage = location.pathname === '/';
  const explorerPage = location.pathname.startsWith('/explorer');
  const searchPage = location.pathname.startsWith('/search');


  return { mainPage, explorerPage, searchPage };
};
