import { Button, Stack, styled } from "@mui/material";
import theme from "../../styles/theme";
import { mobileSmallScreen } from "../../styles/breakpoints";

export const HeaderBrand = styled(Stack)`
  border-left: 2px solid #8866e2;
  padding-left: 1.7rem;
  h1 {
    font-size: 3.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: 0.36px;
    color: #8866e2;
  }

  p {
    font-size: 2.65rem;
    font-weight: normal;
    letter-spacing: 0.29px;
    color: #807e90;
    text-align: left;
  }
`;

export const FormLinkButton = styled(Button)`
  display: flex;
  flex-direction: column;
  background-color: #30146d;
  border-radius: 1rem;
  padding: 1rem 2rem;

  &:hover {
    background-color: #30146d;
  }

  > p {
    font-family: "F37 Judge", sans-serif;

    &:first-of-type {
      font-size: 1.5rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.17px;
      color: #fff;
      opacity: 0.39;
      // ${theme.breakpoints.down("md")} {
      //   font-size: 1.2rem;
      // }
    }

    &:last-of-type {
      font-size: 3rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.33px;
      color: #5b34eb;
      // ${theme.breakpoints.down("md")} {
      //   font-size: 2.4rem;
      // }
    }
  }
`;

export const SearchLink = styled(FormLinkButton)`
  margin-right: 2rem;
  background-color: #d4d2dc;
  //padding: 1.6rem;

  // ${mobileSmallScreen}{
  //   margin-right: 0;
  //   margin-bottom: 2rem;
  // }
  //
  &:hover {
    background-color: #d4d2dc;
  }

  h4 {
    font-family: "F37 Judge", sans-serif;
    font-size: 3rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    color: #5b34eb;
    opacity: 0.9;
  }

  p {
    font-family: "F37 Judge", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #30146d;
    opacity: 0.39;
  }
`;
