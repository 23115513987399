import {ConstantFields} from "../InscriptionChecker.styles";
import {Accordion, AccordionDetails, AccordionSummary, Stack, Typography} from "@mui/material";
import {ExpandMoreOutlined} from "@mui/icons-material";
import React from "react";
import {TJson} from "../../../types/apiTypes";

const AdditionalMetadata = ({data}: {data: TJson}) => {
 return (
     <ConstantFields>
         <Accordion>
             <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                 Display additional metadata
             </AccordionSummary>
             <AccordionDetails>
                 {Object.entries(data)?.map(([prop, value]) => (
                     <Stack>
                         <Typography variant={"h4"}>{prop.replaceAll('_',' ')}:</Typography>
                         <Typography>{value}</Typography>
                     </Stack>
                 ))}
             </AccordionDetails>
         </Accordion>
     </ConstantFields>
 );}

export default AdditionalMetadata;