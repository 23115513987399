import { combineReducers, configureStore } from "@reduxjs/toolkit";
import slice from "./slice";
import snackSlice from "./snack/snackSlice";

export const rootReducer = combineReducers({
  state: slice,
  snack: snackSlice
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});
export type AppState = ReturnType<typeof rootReducer>;
