export function HeaderLogo<T>(props: T) {
  return (
    <svg viewBox="0 0 68 68" width={'6.8rem'} height={'6.8rem'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-233 -80) translate(233 73.041) translate(0 7.9)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path fill="#8866E2" d="M0 0H40.5386515V40.5386515H0z" />
        <path
          fill="#8866E2"
          d="M26.6999469 26.4613485H67.2385984V67H26.6999469z"
        />
        <path
          fill="#FFF"
          d="M26.4613485 26.4613485H40.5386515V40.5386515H26.4613485z"
        />
      </g>
    </svg>
  );
}
