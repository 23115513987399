import { createSlice } from "@reduxjs/toolkit";
import { TJson } from "../types/apiTypes";

const defaultMetadata: TJson = {
  owner: "",
  author: "",
  linked: "",
};

type TInitialState = {
  userId: string;
  metadata: TJson;
  picture: string;
  picName: string;
  inscriptionId: string
};

const initialState: TInitialState = {
  userId: "",
  metadata: defaultMetadata,
  picture: "",
  picName: "",
  inscriptionId: ''
};

const slice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setField: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setField } = slice.actions;

export default slice.reducer;
