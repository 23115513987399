import InscriptionChecker from "../components/inscriptionCheck/InscriptionChecker";

import {LastView} from "../components/lastView/LastView";

export const ExplorerPage = () => {
    return (
        <>
            <InscriptionChecker/>
            <LastView/>
        </>
    )
}