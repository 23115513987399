import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { fetchEmailNotification, fetchGetOrderStatus } from "../../api/apiRequests";
import { useData } from "../../utils/useData";
import { fetchAddMetadata, fetchCurrentBitcoinPrice } from "../../api/apiRequests";
import { useDispatch } from "react-redux";
import { setField } from "../../redux/slice";
import { TJson } from "../../types/apiTypes";
import { useSnack } from "../snack/useSnack";

type TStatusData = {
  image: string;
  status: string;
  inscriptionId: string;
  inscribe_tx: string,
  send_to_user_tx: string
};
export const useTransfer = () => {
  const [satoshiRate, setSatoshiRate] = useState<number>(0);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<TStatusData[]>([]);
  const { userId, picture, picName, metadata } = useData();
  const [email, setEmail] = useState<string>("");
  const [loaderEmail, setLoaderEmail] = useState<boolean>(false);
  const dispatch = useDispatch();
  const snack = useSnack();

  const getApiData = () => {
    const newMetaData: TJson = {};
    for (const [prop, value] of Object.entries(metadata)) {
      if (value) {
        newMetaData[prop] = value;
      }
    }

    const data = {
      uuid: userId,
      data_url: picture,
      filename: picName,
      metadata: newMetaData,
    };

    return data;
  };

  const inscribeClick = async () => {
    setPopupOpen(true);

    try {
      const res = await fetchAddMetadata(getApiData());
      if (res?.data_url) {
        dispatch(setField({ picture: res?.data_url }));
      }
    } catch (err) {
      snack("Something went wrong while trying to add metadata to the image! Please try again later!");
    }
  };

  const downloadImage = async () => {
    try {
      const res = await fetchAddMetadata(getApiData());
      const a: any = document.createElement("a"); //Create <a>
      a.href = res?.data_url; //Image Base64 Goes here
      a.download = picName || "Digital-artifact.png"; //File name Here
      a.click(); //Downloaded file
    } catch (err) {
      snack("Something went wrong while trying to add metadata to the image! Please try again later!");
    }
  };

  useEffect(() => {
    if (!satoshiRate) {
      const fetchData = async () => {
        const res = await fetchCurrentBitcoinPrice();
        const bitcoin = Number(res?.bitcoin?.usd) || 0;

        setSatoshiRate(bitcoin / 100000000);
        // const res = await axios.get(
        //   "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/btc/usd.json"
        // );
        //
        // setSatoshiRate(res?.data?.usd / 100000000);
      };

      void fetchData();
    }
  }, []);

  const getOrderStatus = async () => {
    const res = await fetchGetOrderStatus({ uuid: userId });
    if (res?.length) {
      const newData: TStatusData[] = [];
      res.forEach((data) => newData.push({
        image: data.data_url,
        status: data.status,
        inscriptionId: data.inscription_id,
        inscribe_tx: data.txs?.inscribe_tx ?? '',
        send_to_user_tx: data.txs?.send_to_user_tx ?? ''
      }));
      setStatusData(newData);
    }
  };

  useEffect(() => {
    if (userId) {
      getOrderStatus();
    }
  }, [userId]);

  useEffect(() => {
    const checkForStatusUpdate = setInterval(async () => {
      await getOrderStatus();
    }, 60000);
    return () => {
      clearInterval(checkForStatusUpdate);
    };
  }, []);

  const getEmailNotification = async () => {
    setLoaderEmail(true);
    const res = await fetchEmailNotification({ uuid: userId, email });
    if(res?.status === 'success'){
      snack('Thank you for subscribing! You will receive updates on your e-mail address.', 'success')
    }
    setLoaderEmail(false);
    setEmail("");
  };

  return {
    satoshiRate,
    popupOpen,
    setPopupOpen,
    statusData,
    inscribeClick,
    email,
    setEmail,
    getEmailNotification,
    loaderEmail,
    downloadImage,
  };
};
