import { Button, Dialog, DialogContent, FormLabel, Stack } from "@mui/material";
import { AddFieldButton, CreatorFormControl, FormInput } from "./Create.styles";
import { PlusIcon } from "./Create.icons";
import React, { useRef } from "react";
import { CloseButton } from "../../styles/basicLayout";
import { CrossIcon } from "../../assets/svg/icons";
import {useSnack} from "../snack/useSnack";

type TAddFieldPopupProps = {
  open: boolean;
  close: () => void;
  apply: (prop: string, value: string) => void;
};

const AddFieldPopup = ({ open, close, apply }: TAddFieldPopupProps) => {
  const propRef: any = useRef(null);
  const valueRef: any = useRef();
  const snack = useSnack()

  const handleCreate = () => {
    const prop: string = propRef?.current?.value;
    const val: string = valueRef?.current?.value;

    if (!prop || !val) return snack('Please fill in all the fields!', 'error');

    apply(prop, val);
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{ sx: { borderRadius: "2rem" } }}
    >
      <DialogContent>
        <CloseButton onClick={close}>
          <CrossIcon />
        </CloseButton>

        <Stack p={"3rem 2.5rem"}>
          <CreatorFormControl>
            <FormLabel>Property name</FormLabel>
            <FormInput
              placeholder={"Custom property name"}
              inputProps={{ ref: propRef }}
            />
          </CreatorFormControl>
          <CreatorFormControl>
            <FormLabel>Value</FormLabel>
            <FormInput placeholder={"Value"} inputProps={{ ref: valueRef }} />
          </CreatorFormControl>
          <AddFieldButton startIcon={<PlusIcon />} onClick={handleCreate}>
            Add To my metadata
          </AddFieldButton>{" "}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddFieldPopup;
