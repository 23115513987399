//core
import { capitalize, FormLabel, IconButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";


// styles

import {
  AddFieldButton,
  CreateBackground,
  CreateContainer,
  Creator,
  CreatorForm,
  CreatorFormControl,
  CreatorImage,
  FormInput,
  GenerateImageButton,
  ImageUpload,
} from "./Create.styles";
import { FlexBox } from "../../styles/basicLayout";

//components
import AddFieldPopup from "./AddFieldPopup";

//hooks
import { useCreate } from "./useCreate";


//images
import { ArrowIcon, BulbIcon, ImageUploadIcon, PlusIcon, TrashIcon } from "./Create.icons";

const Create = () => {
  const {
    metaData,
    popupOpen,
    setPopupOpen,
    updateMetadata,
    removeField,
    addNewField,
    uploadImage,
    picture,
    storeMetadata,
    generateImage,
    isGenerate,
  } = useCreate();

  return (
    <CreateBackground>
      <CreateContainer>
        <Typography variant={"h2"}>Create a Digital Artifact</Typography>
        <Typography>
          Upload your pics with valuable metadata to create permanent, Immutable and officially-owned digital artifacts that will live
          forever on the Bitcoin blockchain.
        </Typography>
        <Creator
          container
          columnSpacing={{ xs: "1rem", md: "14.4rem" }}
          rowSpacing={"9.3rem"}
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid2 xs={12} md={6}>
            <CreatorImage>
              <label>
                <input
                  type={"file"}
                  accept="image/*"
                  hidden
                  disabled={isGenerate}
                  onChange={(e: any) => uploadImage(e?.target?.files[0] as File)}
                />

                <ImageUpload className={isGenerate ? "disabled" : ""}>
                  {picture ? (
                    <img src={picture} />
                  ) : (
                    <>
                      <ImageUploadIcon />
                      <Typography>
                        Upload <br /> Image
                      </Typography>
                    </>
                  )}
                </ImageUpload>
                <Typography color={"rgba(255,255,255,0.7)"} sx={{ textAlign: "right", fontSize: "1.2rem", mt: ".5rem" }}>
                  * max image size = 350 Kb
                </Typography>
              </label>
              <Typography>OR</Typography>
              <GenerateImageButton startIcon={!isGenerate && <BulbIcon />} onClick={() => generateImage()} loading={isGenerate}>
                Generate Image
              </GenerateImageButton>
              <ArrowIcon className={"arrow"} />
            </CreatorImage>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <CreatorForm>
              <Typography variant={"h3"}>Add metadata to Incription</Typography>
              {Object.entries(metaData).map(([prop, value], i: number) => (
                <CreatorFormControl key={prop + i}>
                  <FormLabel>{capitalize(prop.replace("_", " "))}</FormLabel>
                  <FlexBox>
                    <FormInput
                      inputProps={{ placeholder: capitalize(prop), name: prop }}
                      value={value}
                      onChange={updateMetadata}
                      onBlur={storeMetadata}
                    />
                    <IconButton className={"trash"} onClick={() => removeField(prop)}>
                      <TrashIcon />
                    </IconButton>
                  </FlexBox>
                </CreatorFormControl>
              ))}
              <AddFieldButton startIcon={<PlusIcon />} onClick={() => setPopupOpen(true)}>
                Add custom field
              </AddFieldButton>
            </CreatorForm>
          </Grid2>
        </Creator>
      </CreateContainer>
      <AddFieldPopup open={popupOpen} close={() => setPopupOpen(false)} apply={addNewField} />
    </CreateBackground>
  );
};

export default Create;
