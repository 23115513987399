import { AppBar, Toolbar, Typography } from "@mui/material";
import { FlexBox, SectionContainer } from "../../styles/basicLayout";

import {FormLinkButton, HeaderBrand, SearchLink} from "./Header.styles";
import { usePage } from "../../utils/usePage";
import { WhiteButton } from "../../styles/buttons";
import { HeaderLogo } from "./Header.icons";
import { Link } from "react-router-dom";
import { useBodyColor } from "../../utils/useBodyColor";
import { useUserData } from "../../utils/useUserData";

const Header = () => {
  const { mainPage, explorerPage } = usePage();
  useBodyColor();
  useUserData();

  return (
    <AppBar elevation={0} color={"transparent"} position={"relative"}>
      <Toolbar>
        <SectionContainer pt={"11rem"}>
          <FlexBox flexDirection={{ xs: "column", md: "row" }} rowGap={"5rem"}>
            <FlexBox>
              <HeaderLogo style={{ marginRight: "2rem" }} />
              <HeaderBrand>
                <Typography variant={"h1"}>Digital-Artifacts.io</Typography>
                {/*<Typography>By Web3m</Typography>*/}
              </HeaderBrand>
            </FlexBox>
            {mainPage && (
              <FlexBox>
                <SearchLink>
                  <Link to={'/search'}>
                    <Typography>Ordinals</Typography>
                    <Typography variant={'h4'}>Search</Typography>
                  </Link>
                </SearchLink>
                <FormLinkButton onClick={() => window.open("https://kzfr8q2sfex.typeform.com/to/lfMXMuAt", "_typeform")}>
                  <Typography>Bulk Request</Typography>
                  <Typography>Get API</Typography>
                </FormLinkButton>
              </FlexBox>
            )}

            {!mainPage && (
              <Link to={"/"}>
                <WhiteButton>Create a Digital Artifact</WhiteButton>
              </Link>
            )}
          </FlexBox>
        </SectionContainer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
