import {Button, styled} from "@mui/material";
import {hellixMedium} from "./typography";


export const WhiteButton = styled(Button)`
  ${hellixMedium}
  font-size: 2.95rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-transform: none;
  letter-spacing: 0.33px;
  color: #8866e2;

  border-radius: 1.9rem;
  border: solid 1px #8866e2;
  background-color: #fff;
  padding: 2.6rem 3.5rem;
  
  &:hover{
    background-color: #fff;

  }
`