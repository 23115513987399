import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {usePage} from "./usePage";

export const useBodyColor = () => {
  const { pathname } = useLocation();
  const {mainPage, explorerPage, searchPage} = usePage()


  useEffect(() => {
    if (mainPage) {
      document.body.style.backgroundColor = '#0e0529'
    }
    else {
        document.body.style.backgroundColor = '#fff'
    }
  }, [pathname]);

  return;
};
