export function ImageUploadIcon<T>(props:T) {
    return (
        <svg viewBox="0 0 62 43" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M52.006 2.64c-9.897 0-17.98 7.78-18.46 17.555a1.32 1.32 0 01-.994 1.215c-3.998 1.02-6.953 4.645-6.953 8.957a9.24 9.24 0 009.243 9.242h5.28a1.32 1.32 0 010 2.641h-5.28c-6.562 0-11.883-5.32-11.883-11.883 0-5.21 3.353-9.633 8.014-11.238C31.98 8.397 41.013 0 52.006 0c7.906 0 14.798 4.346 18.418 10.771 7.526 1.23 13.27 7.76 13.27 15.635 0 8.749-7.096 15.844-15.845 15.844h-1.32a1.32 1.32 0 010-2.64h1.32c7.294 0 13.204-5.91 13.204-13.204 0-6.76-5.081-12.334-11.63-13.11a1.317 1.317 0 01-1.014-.7c-3.084-5.92-9.274-9.955-16.403-9.955zm.385 16.23a1.324 1.324 0 011.87 0l7.922 7.922a1.324 1.324 0 010 1.87 1.324 1.324 0 01-1.87 0l-5.667-5.67V40.93a1.32 1.32 0 01-2.64 0V22.992l-5.667 5.67a1.324 1.324 0 01-1.87 0 1.324 1.324 0 010-1.87l7.922-7.922z"
                transform="translate(-420 -761) translate(397.885 761.441)"
                fill="#8A47CE"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function PlusIcon<T>(props:T) {
    return (
        <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" {...props}>
            <defs>
                <linearGradient
                    x1="82.8186401%"
                    y1="-27.0449467%"
                    x2="29.0791135%"
                    y2="100%"
                    id="a"
                >
                    <stop stopColor="#5633F6" offset="0%" />
                    <stop stopColor="#753AB1" offset="100%" />
                </linearGradient>
            </defs>
            <g
                transform="translate(-908 -1097) translate(908.206 1097.439)"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <circle fill="url(#a)" cx={18.5931698} cy={18.5931698} r={18.5931698} />
                <text
                    fontFamily="Hellix-Regular, Hellix"
                    fontSize={35.35}
                    fontWeight="normal"
                    letterSpacing={0.392777376}
                    fill="#FFF"
                >
                    <tspan x={8.22157358} y={29.5931698}>
                        {"+"}
                    </tspan>
                </text>
            </g>
        </svg>
    )
}

export function BulbIcon<T>(props:T) {
    return (
        <svg viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M342.909 1120.927a.846.846 0 011.194 0l1.69 1.69a.846.846 0 01-1.194 1.195l-1.69-1.69a.846.846 0 010-1.195m17.498 1.443c-6.067 0-10.985 4.918-10.985 10.985 0 2.724.988 5.215 2.633 7.137 1.369 1.597 2.809 3.446 3.573 5.538h9.557c.762-2.092 2.203-3.941 3.572-5.538a10.944 10.944 0 002.635-7.137c0-6.067-4.92-10.985-10.985-10.985m4.33 25.35h-8.662c.068.424.107.859.107 1.303v1.232a4.225 4.225 0 008.45 0v-1.232c0-.444.035-.879.105-1.303m-17.005-14.365c0-7.002 5.673-12.675 12.675-12.675 7 0 12.675 5.673 12.675 12.675 0 3.142-1.146 6.02-3.04 8.235-1.493 1.745-2.862 3.558-3.438 5.522a6.788 6.788 0 00-.282 1.911v1.232a5.916 5.916 0 01-11.83 0v-1.232c0-.65-.103-1.286-.284-1.911-.576-1.964-1.945-3.777-3.438-5.522a12.633 12.633 0 01-3.038-8.235m30.172-12.428c.33.33.33.865 0 1.195l-1.69 1.69a.846.846 0 01-1.195-1.195l1.69-1.69a.846.846 0 011.195 0m-32.11 21.97c.329.33.329.865 0 1.195l-1.69 1.69a.846.846 0 01-1.195-1.195l1.69-1.69a.846.846 0 011.194 0m29.226 0a.846.846 0 011.195 0l1.69 1.69a.846.846 0 01-1.195 1.195l-1.69-1.69a.846.846 0 010-1.195"
                transform="translate(-342 -1120)"
                fill="#C3C1D8"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function ArrowIcon<T>(props:T) {
    return (
        <svg
            viewBox="0 0 328 202"
            width={'32.8rem'}
            height={'20.2rem'}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <defs>
                <path id="arrow-a" d="M0 0L131.635024 0 131.635024 304.2 0 304.2z" />
                <linearGradient
                    x1="61.7823538%"
                    y1="70.8047595%"
                    x2="59.3626341%"
                    y2="0%"
                    id="arrow-e"
                >
                    <stop stopColor="#6533AE" offset="0%" />
                    <stop stopColor="#351776" offset="100%" />
                </linearGradient>
                <radialGradient
                    cx="31.8860699%"
                    cy="126.047567%"
                    fx="31.8860699%"
                    fy="126.047567%"
                    r="138.091738%"
                    gradientTransform="matrix(-.50794 .37275 -.33848 -.08637 .907 1.25)"
                    id="arrow-f"
                >
                    <stop stopColor="#FFF" stopOpacity={0.5} offset="0%" />
                    <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
                </radialGradient>
                <path
                    d="M129.383 125.072C121.72 72.44 92.485 26.945 54.045 0c27.47 38.353 36.333 84.941 28.276 125.077-7.776 40.454-31.807 73.404-61.555 91.794L-.001 196.106V304.2h108.096l-20.77-20.765c36.164-46.908 50.227-106.05 42.058-158.363"
                    id="arrow-d"
                />
                <filter
                    x="-19.4%"
                    y="-2.8%"
                    width="125.8%"
                    height="109.2%"
                    filterUnits="objectBoundingBox"
                    id="arrow-c"
                >
                    <feOffset
                        dx={-13}
                        dy={7}
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.186199055 0"
                        in="shadowBlurOuter1"
                    />
                </filter>
            </defs>
            <g
                transform="translate(-655 -691) scale(1 -1) rotate(76 1046.718 161.776)"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <mask id="arrow-b" fill="#fff">
                    <use xlinkHref="#arrow-a" />
                </mask>
                <g mask="url(#arrow-b)">
                    <use fill="#000" filter="url(#arrow-c)" xlinkHref="#arrow-d" />
                    <use fill="url(#arrow-e)" xlinkHref="#arrow-d" />
                    <use fill="url(#arrow-f)" xlinkHref="#arrow-d" />
                </g>
            </g>
        </svg>
    )
}

export function TrashIcon<T>(props:T) {
    return (
        <svg
            viewBox="0 0 17 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.096.835c-.692 0-1.253.56-1.253 1.252v1.252h4.174V2.087c0-.692-.56-1.252-1.252-1.252h-1.67zm3.756 2.504V2.087A2.087 2.087 0 008.765 0h-1.67A2.087 2.087 0 005.01 2.087v1.252H.417a.418.418 0 000 .835H.88l1.463 13.17A2.086 2.086 0 004.416 19.2h7.029c1.063 0 1.957-.8 2.073-1.857l1.464-13.17h.461a.418.418 0 000-.834h-4.59zm3.29.835H1.72L3.17 17.252a1.252 1.252 0 001.245 1.113h7.029c.638 0 1.174-.48 1.245-1.113l1.452-13.078zm-8.177 5.13a.419.419 0 01.591 0L7.93 10.68l1.374-1.375a.419.419 0 01.592.592L8.52 11.27l1.375 1.374a.419.419 0 01-.592.59L7.93 11.86l-1.374 1.375a.419.419 0 01-.59-.591L7.34 11.27 5.965 9.896a.419.419 0 010-.592z"
                transform="translate(-1546 -1086) translate(1546.8 1086.037)"
                fill={'red'}
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}