import {
    GasFeeButtons, StatusTitle,
    SubscribeForm,
    TransferActionButtons,
    TransferActions,
    TransferBackground,
    TransferCalculations,
    TransferCheckStatus,
    TransferDownloadButtonTips,
} from "./Transfer.styles";
import {
    FlexBox,
    SectionContainer,
    SectionDivider,
} from "../../styles/basicLayout";
import {
    Box,
    Button,
    FormLabel,
    Grid,
    IconButton,
    Link,
    Stack,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, {Fragment, useState} from "react";
import {transferModes} from "./Transfer.data";
import {FormInput} from "../create/Create.styles";
import {useTransfer} from "./useTransfer";
import Payment from "./popups/Payment";
import {useData} from "../../utils/useData";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setField} from "../../redux/slice";

const Transfer = () => {
    const [speed, setSpeed] = useState(0);
    const mobileUI = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    // const {picture} = useData()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        satoshiRate,
        popupOpen,
        setPopupOpen,
        inscribeClick,
        statusData,
        email,
        setEmail,
        getEmailNotification,
        loaderEmail,
        downloadImage,
    } = useTransfer();


    const isTxStatus = (status: string) => {
        return (status === 'inscribe tx fired' || status === 'sent_to_user tx fired')
    }
    const isInscriptionId = (status: string) => {
        return (status === 'completed' || isTxStatus(status))
    }

    return (
        <TransferBackground>
            <SectionContainer>
                <TransferActions>
                    <Typography variant={"h3"}>Gas fee:</Typography>
                    <GasFeeButtons>
                        {transferModes.map((mode, index) => (
                            <Button
                                key={mode.type}
                                className={index === speed ? "active" : ""}
                                onClick={() => setSpeed(index)}
                            >
                                <Typography>{mode.type}:</Typography>
                                <Typography>{mode.price} Sat</Typography>
                            </Button>
                        ))}
                    </GasFeeButtons>
                    <TransferCalculations>
                        <Typography>
                            Network fees: <span> {transferModes[speed].price} Sat</span>
                        </Typography>
                        <Typography>
                            Total amount (included network fees):{" "}
                            <span>
                {transferModes[speed].price + 4} Sat = $
                                {Math.round(
                                    (transferModes[speed].price + 4) * satoshiRate * 10000
                                ) / 10000}{" "}
                                USD
              </span>
                        </Typography>
                    </TransferCalculations>
                    <TransferActionButtons>
                        <Button onClick={inscribeClick}>
                            Inscribe to the Bitcoin Blockchain
                        </Button>
                        <Typography>
                            OR{" "}
                            {mobileUI &&
                                "You can download your picture with its metadata already embedded inside, for free. Feel free to take it and inscribing using your own node or any other service."}
                        </Typography>
                        <Box className={"download"}>
                            <Button
                                onClick={downloadImage}
                            >
                                Download image + Metadata{" "}
                            </Button>
                            <TransferDownloadButtonTips>
                                <Typography>Free</Typography>
                                <Tooltip
                                    title={
                                        "You can download your picture with its metadata already embedded inside, for free. Feel free to take it and inscribing using your own node or any other service."
                                    }
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: "#200c53",
                                                color: "#fff",
                                                fontSize: "2rem",
                                                padding: "2rem 3rem",
                                                borderRadius: "2rem",
                                                border: "1px solid #31156f",
                                            },
                                        },
                                    }}
                                    // placement={"bottom"}
                                >
                                    <Box component={"span"}>
                                        <i onClick={(e) => e.stopPropagation()}>i</i>
                                    </Box>
                                </Tooltip>
                            </TransferDownloadButtonTips>
                        </Box>
                    </TransferActionButtons>
                </TransferActions>
            </SectionContainer>
            <SectionDivider/>
            <SectionContainer>
                <TransferCheckStatus>
                    <Stack
                        alignItems={{xs: "center", md: "flex-start"}}
                        mb={"3rem"}
                    >
                        <Typography variant={"h3"}>Check transaction status:</Typography>
                        <Grid container width={"100%"}>
                            {statusData?.map(({image, status, inscriptionId, inscribe_tx, send_to_user_tx}, i) => (
                                <FlexBox key={i} mb={{xs: "6rem", md: "5rem", lg: "4rem", xl: "3rem"}} width={'100%'} height={'fit-content'}>
                                    <Grid item xs={3} sm={2} md={1.5} maxHeight={'6rem'} maxWidth={'60rem'}>
                                        <img src={image} width={"60rem"} height={"100%"}/>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10.5}
                                          sx={{display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap'}}
                                    >
                                        <Typography
                                            variant={"h4"}
                                            sx={{
                                                textDecoration:
                                                    (isInscriptionId(status) && inscriptionId) ? "underline" : "",
                                                cursor:
                                                    (isInscriptionId(status) && inscriptionId) ? "pointer" : "inherit",
                                            }}
                                            onClick={() => {
                                                if (isInscriptionId(status) && inscriptionId) {
                                                    dispatch(setField({inscriptionId}));
                                                    navigate(`/explorer/${inscriptionId}`);
                                                }
                                            }}
                                        >
                                            {status.split("_").join(" ")}
                                        </Typography>
                                        {isInscriptionId(status) &&
                                            (status === 'inscribe tx fired' ?
                                                inscribe_tx &&
                                                <Link variant={'h5'}
                                                  href={`https://mempool.space/tx/${inscribe_tx}`}
                                                  underline="hover"
                                                  target={'_blank'}
                                            >
                                                {" ( Details )"}
                                            </Link>
                                            : send_to_user_tx &&
                                                <Link variant={'h5'}
                                                      href={`https://mempool.space/tx/${send_to_user_tx}`}
                                                      underline="hover"
                                                      target={'_blank'}
                                                >
                                                    {" ( Details )"}
                                                </Link>)}
                                    </Grid>
                                </FlexBox>
                            ))}
                        </Grid>
                    </Stack>
                    <SubscribeForm>
                        <FormLabel>Enter your email to receive updates</FormLabel>
                        <FlexBox>
                            <FormInput
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                inputProps={{placeholder: "Type here...", type: "email"}}
                            />
                            <LoadingButton
                                onClick={getEmailNotification}
                                loading={loaderEmail}
                            >
                                Get notified
                            </LoadingButton>
                        </FlexBox>
                    </SubscribeForm>
                </TransferCheckStatus>
            </SectionContainer>
            <SectionDivider/>
            <Payment
                satoshiRate={satoshiRate}
                open={popupOpen}
                fee={transferModes[speed]?.price}
                close={() => setPopupOpen(false)}
            />
        </TransferBackground>
    );
};

export default Transfer;
