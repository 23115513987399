import {Box, Stack, styled, Typography} from "@mui/material";
import {ImgBox} from "../../styles/basicLayout";

export const LastViewContainer = styled(Stack)`
  //width: 100vw;
  .swiper-slide {
    width: 20%;
    min-width: 20rem;
    max-width: 30rem;
    //width: 110vw;
    //margin-left: -5vw;
  }
  padding-bottom: 9.3rem;
`

export const LastViewTitle = styled(Typography)`
  font-size: 3.3rem;
  font-weight: bold;
  line-height: 1.09;
  letter-spacing: 0.37px;
  color: #594c7d;
  margin-bottom: 6.6rem;
`

export const SlideBox = styled(ImgBox)`
  margin: 2rem 0;
  cursor: pointer;
  transition: .3s;
  border-radius: 1rem;
  
  img{
    border-radius: 1rem;
  }
  
  iframe{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    cursor: pointer;
    pointer-events: unset !important;
  }
  
  .click-zone{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  &:hover{
    transform: scale(1.05);
  }
  //width: 26.2rem;
  //height: 26.2rem;
`