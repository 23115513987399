import { useState } from "react";
import {useData} from "../../utils/useData";
import {useDispatch} from "react-redux";
import {setField} from "../../redux/slice";
import {
  fetchGenerateImage,
  fetchReadMetadata
} from "../../api/apiRequests";
import {TJson} from "../../types/apiTypes";
import {read} from "fs";
import {useSnack} from "../snack/useSnack";

const initialMetaData = {
  collection: "",
  artist: "",
  link: "",
};

export const useCreate = () => {
  const [metaData, setMetadata] = useState<TJson>(initialMetaData);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [pic, setPic] = useState<string>('');
  const [isGenerate, setIsGenerate] = useState<boolean>(false)

  const dispatch = useDispatch()
  const snack = useSnack()

  const {picture, userId} = useData();

  const updateMetadata = (e: any) => {
    setMetadata({ ...metaData, [e.target.name]: e.target.value });
  };

  const removeField = (field: string) => {
    const newData = {...metaData};
    delete newData[field as keyof typeof metaData];
    setMetadata(newData)
  }

  const addNewField = (prop: string, value: string) => {
    setMetadata({...metaData, [prop]: value})
    dispatch(setField({metadata: {...metaData, [prop]: value}}))
  }

  const generateImage = async () => {
    setIsGenerate(true)
    try{
      const image = await fetchGenerateImage({uuid: userId})
      dispatch(setField({picture: image}))
      setIsGenerate(false)
    }catch(err){
      snack('Something went wrong while trying to generate image. Please try again later!', 'error')
    }

  }

  const storeMetadata = () => {
    dispatch(setField({metadata: metaData}))
  }

  const uploadImage = async (file: File) => {
    if(file.size > 350000) return snack('Max image size is 350 Kb!', 'error');

    let reader = new FileReader();
    reader.readAsDataURL(file as File);
    reader.onload = async function () {
      dispatch(setField({picture: reader?.result, picName: file.name}))
      if (reader?.result) {
        const data = {
          uuid: userId,
          data_url: reader?.result as string,
          filename: file.name
        }

        const res = await fetchReadMetadata(data);

        if(res){
          setMetadata(res)
          dispatch(setField({metadata: res}))
        }
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

  }
  return {
    metaData,
    popupOpen,
    setPopupOpen,
    updateMetadata,
    removeField,
    addNewField,
    generateImage,
    isGenerate,
    uploadImage,
    picture,
    storeMetadata
  };
};
