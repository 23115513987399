import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import theme from "../../styles/theme";
import { FlexBox } from "../../styles/basicLayout";

export const FAQContainer = styled(Box)`
  background-image: linear-gradient(162deg, #250e5b 0%, #0e0529 99%);
  border-top: 1px solid rgba(255, 255, 255, 0.35);
`;

export const AccordionContainer = styled(Stack)`
  padding-bottom: 12.5rem;
`;

export const FAQTitle = styled(Typography)`
  font-size: 4.23rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #fff;
  margin: 12.1rem 0 5.3rem;
  ${theme.breakpoints.down("md")} {
    text-align: center;
  }
`;

export const QuestionsBlock = styled(Stack)`
margin-bottom: 5rem;
`

export const FAQSubtitle = styled(Typography)`
  font-size: 3.2rem;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 3rem;
  margin-bottom: 1rem;
`;

export const AccordionStyled = styled(Accordion)`
  margin-bottom: 3.1rem;
  border: 1px solid #31156f;
  border-radius: 3.2rem;
  transition: 0ms;
  background-color: unset;

  &:first-of-type,
  &:last-of-type {
    border-radius: 3.2rem;
  }
  &.Mui-expanded {
    margin: 1.6rem 0 4rem;
  }
`;
export const Summary = styled(AccordionSummary)`
  color: #b3b3b3;
  padding: 2rem;
  background-image: linear-gradient(244deg, #200c53 20%, #0e0529 100%);
  border-radius: 3.2rem;
  transition: 0ms;
  //& .MuiAccordionSummary-expandIconWrapper {
  //  transform: unset;
  //  &.Mui-expanded {
  //    transform: unset;
  //  }
  //}
  &.Mui-expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: unset;
    background-color: #3a1a7e;
    transition: 0ms;
    border: none;
  }
  //@media (max-width: 600px) {
  //  padding: 1rem 1rem 1rem 2rem;
  //}
  ${theme.breakpoints.down("md")} {
    padding: 4rem 7rem 2rem;
  }
`;
export const SummaryText = styled(Typography)`
  font-size: 2.61rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.29px;
  //@media (max-width: 600px) {
  //  font-size: 1.8rem;
  //  line-height: 1.5;
  //}
`;
export const Details = styled(AccordionDetails)`
  display: flex;
  flex-direction: row;
  font-size: 2.61rem;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: 0.29px;
  border-bottom-left-radius: 3.2rem;
  border-bottom-right-radius: 3.2rem;
  background-color: #3a1a7e;
  color: #ffffff;
  padding: 0 5vw 5rem 2rem;
  text-align: left;
  transition: 0ms;
  //@media (max-width: 600px) {
  //  font-size: 1.7rem;
  //  line-height: 1.6;
  //}
  ${theme.breakpoints.down("md")} {
    padding: 0 7rem 5rem;
  }
`;

const ColorBox = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 4.23rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.47px;
  margin-right: 2rem;
  min-width: 5.9rem;
  width: 5.9rem;
  height: 5.9rem;
`;
export const QuestionImage = styled(ColorBox)`
  background-color: #fff;
  color: #220d55;
`;

export const AnswerImage = styled(ColorBox)`
  background-color: #5b34ec;
  color: #ffffff;
`;
