//Core
import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { store } from "./redux/store";
//Components
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { MainPage } from "./pages/MainPage";
import { ExplorerPage } from "./pages/ExplorerPage";
//Styles
import theme from "./styles/theme";
import "../src/assets/fonts/fonts.css";
import "@fontsource/holtwood-one-sc";
import { Provider } from "react-redux";
import SearchPage from "./pages/SearchPage";
import {SnackbarProvider} from "notistack";
import {Snack} from "./components/snack/Snack";

function App() {
  return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={2}
            hideIconVariant
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            preventDuplicate
          >
            <CssBaseline />
              <Snack/>
            <Header />
            <Routes>
              <Route path={"/"} element={<MainPage />} />
              <Route path={"explorer"} element={<ExplorerPage />} />
              <Route path={"explorer/:id"} element={<ExplorerPage />} />
              <Route path={"search"} element={<SearchPage />} />
              <Route path={"*"} element={<Navigate to={"/"} replace />} />
            </Routes>
            <Footer />
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
  );
}

export default App;
