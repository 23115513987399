export function MagnifyingGlassIcon<T>(props: T) {
  return (
    <svg width="4.8rem" height="4.8rem" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M36.727 21.773c-8.259 0-14.954 6.695-14.954 14.954 0 8.26 6.695 14.954 14.954 14.954 4.13 0 7.866-1.67 10.575-4.38a14.902 14.902 0 004.38-10.574c0-8.259-6.696-14.954-14.955-14.954M17.5 36.727c0-10.62 8.607-19.227 19.227-19.227 10.62 0 19.227 8.607 19.227 19.227 0 4.54-1.574 8.714-4.204 12.002l12.124 12.124a2.134 2.134 0 010 3.02 2.134 2.134 0 01-3.021 0L48.729 51.75a19.153 19.153 0 01-12.002 4.204c-10.62 0-19.227-8.607-19.227-19.227"
        transform="translate(-1386 -551) translate(95.2 468) translate(1273.5 66)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}
