import { useCookies } from "react-cookie";
import { useEffect, useId } from "react";
import uuid from "react-uuid";
import { useDispatch } from "react-redux";
import { setField } from "../redux/slice";

export const useUserData = () => {
  const [cookies, setCookies] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookies.device_uuid) {
      dispatch(setField({ userId: cookies.device_uuid }));
      return;
    }
    const id = uuid();
    setCookies("device_uuid", id);
    dispatch(setField({ userId: id }));
  }, [cookies.device_uuid]);

  return;
};
