import {useSelector} from "react-redux";
import {AppState} from "../redux/store";


export const useData = () => {
    const state = useSelector((state:AppState) => state.state);


    return state;

}