// export const FAQData = [
//   {
//     question: "What file formats can I upload and inscribe?",
//     answer:
//       "Only jpeg or png are supported in the alpha versions. Soon we will open it to more audio, video and document file formats.",
//   },
//   {
//     question: "Must I only inscribe here?",
//     answer:
//       "The metadata of your picture is already embedded inside the picture, so you can download it for free, and use it to inscribe wherever you please.",
//   },
//   {
//     question: "What happens to my inscription after I click 'Inscribe'?",
//     answer:
//       "You will receive the inscription in your bitcoin wallet which you enter above. In case you don’t have a bitcoin wallet yet, you can still inscribe and come back later and we then send it to you. In the meanwhile, we will keep it safe for you.",
//   },
// ];


export const inscriptionData = [
  {
    question: "What file formats can I upload and inscribe?",
    answer:
      "Only jpeg or png are supported in the alpha versions. Soon we will open it to more audio, video and document file formats.",
  },
  {
    question: "Must I only inscribe here?",
    answer:
      "The metadata of your picture is already embedded inside the picture, so you can download it for free, and use it to inscribe wherever you please.",
  },
  {
    question: "What happens to my inscription after I click 'Inscribe'?",
    answer:
      "You will receive the inscription in your bitcoin wallet which you enter above. In case you don’t have a bitcoin wallet yet, you can still inscribe and come back later and we then send it to you. In the meanwhile, we will keep it safe for you.",
  },
];

export const metaData = [
  {
    question: "Why do I need metadata?",
    answer:
      "Adding metadata to your inscription is valuable if you want to identify the inscription to a larger collection, add yourself as or give credit to the creator, and simply add any valuable information to the inscription (like the good old days, when you could just right click on a file to see its properties).",
  },
  {
    question: "What custom fields can I add to the metadata?",
    answer:
      "Besides the author’s name and date, you can also include links, collection names, or any other valuable information you wish to keep forever in your inscription.",
  },
  {
    question: "How is the metadata uploaded to the blockchain?",
    answer:
      "This information will be embedded in the inscription, so anyone can see it.",
  },
  {
    question:
      "Can the metadata be updated or modified after it has been uploaded to the blockchain?",
    answer:
      "No. Once it’s on the blockchain, it is not possible to update or modify it.",
  },
  {
    question:
      "How does embedding metadata enhance the value of the inscription for users?",
    answer:
      "Having the ability to identify the author of an inscription or its membership in a set of inscriptions created by the same person is a key feature that's still lacking in the market. We solve this by embedding this information as the metadata inside an inscription.",
  },
  {
    question:
      "Is there a limit to the amount of metadata that can be embedded in each inscription using your tool?",
    answer:
      "According to the standard way that Ordinals and Inscriptions are made, we are limited to 350 KB per Inscription (=1 Satoshi).",
  },
];

export const paymentsData = [
  {
    question: "What currencies can I pay with?",
    answer: "Currently, only native BTC. Not supporting lightning network yet.",
  },  {
    question: "How are gas fees calculated here?",
    answer: "The calculation is simple - the amount of gas fee you choose X bite size of the file you inscribe.\n" +
        "For example, a 400kb inscription (which are 409600 bytes) * 6 sat gas fee, will be a total price of 2457600 sat = 0.024 BTC\n",
  },
];

export const FAQData = {
    inscription: inscriptionData,
    metadata: metaData,
    payments: paymentsData
};
