//Core
import React, { useState } from "react";
//Styles
import {
    AccordionContainer,
    AccordionStyled,
    AnswerImage,
    Details,
    FAQContainer, FAQSubtitle,
    FAQTitle,
    QuestionImage, QuestionsBlock,
    Summary,
    SummaryText,
} from "./FAQ.styles";
import { SectionContainer } from "../../styles/basicLayout";
//Data
import { FAQData } from "./FAQData";
import { Typography } from "@mui/material";
import {useSnack} from "../snack/useSnack";

const Faq = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <FAQContainer>
      <SectionContainer>
        <FAQTitle>{"FAQ"}</FAQTitle>
        <AccordionContainer>
          {Object.entries(FAQData).map(([title, data]) => (
            <QuestionsBlock key={title}>
              <FAQSubtitle>{title}</FAQSubtitle>

              {data.map(({ question, answer }, i) => (
                <AccordionStyled
                  key={i}
                  expanded={expanded === question}
                  onChange={handleChange(question)}
                >
                  <Summary
                  // expandIcon={expanded ? <CloseStyled color={'secondary'}/> : <ArrowStyled color={'primary'}/> }
                  >
                    <QuestionImage>{"Q"}</QuestionImage>
                    <SummaryText>{question}</SummaryText>
                  </Summary>
                  <Details>
                    <AnswerImage>{"A"}</AnswerImage>
                    {answer}
                  </Details>
                </AccordionStyled>
              ))}
            </QuestionsBlock>
          ))}
        </AccordionContainer>
      </SectionContainer>
    </FAQContainer>
  );
};

export default Faq;
