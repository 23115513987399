import {
  ConstantFields,
  ExplorerTitle,
  ImagePreview,
  InscriptionCheckBackground,
  InscriptionInputBlock,
  PropsPreview,
} from "./InscriptionChecker.styles";
import { FlexBox, SectionContainer } from "../../styles/basicLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  OutlinedInput,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useInscriptionChecker } from "./useInscriptionChecker";
import { TelescopeIcon } from "./InscriptionChecker.icons";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { LastViewContainer, LastViewTitle, SlideBox } from "../lastView/LastView.styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay } from "swiper";
import { ExpandMore, ExpandMoreOutlined } from "@mui/icons-material";
import AdditionalMetadata from "./components/AdditionalMetadata";
import PreviewIframe from "../iFrame/PreviewIframe";
const InscriptionChecker = () => {
  const { fields, pic, inscriptionInput, setInscriptionInput, isLoading, show, name, list, chooseLastViewed, constantFields } =
    useInscriptionChecker();

  return (
    <>
      <SectionContainer>
        <ExplorerTitle variant={"h2"}>Ordinals Explorer</ExplorerTitle>
      </SectionContainer>
      <InscriptionCheckBackground>
        <InscriptionInputBlock>
          <FormControl>
            <FormLabel>Inscription ID:</FormLabel>
            <FlexBox>
              <OutlinedInput placeholder={"Type here..."} value={inscriptionInput} disabled={isLoading} onChange={(e) => setInscriptionInput(e.target.value)} />
              <LoadingButton loading={isLoading} onClick={show}>
                Show
              </LoadingButton>
            </FlexBox>
          </FormControl>
        </InscriptionInputBlock>
      </InscriptionCheckBackground>
      <SectionContainer>
        <Typography variant={"h3"} fontWeight={"600"} mb={"2rem"} color={"#7239b5"}>
          {name}
        </Typography>
        <Grid2 container spacing={"6rem"} mb={"10rem"}>
          <Grid2 xs={12} md={6}>
            <ImagePreview className={!pic || isLoading ? "noPic" : ""}>
              {isLoading ? (
                  <CircularProgress />
              ) : pic ? (
                  <PreviewIframe id={pic}/>
              ) : (
                <TelescopeIcon />
              )}
            </ImagePreview>
          </Grid2>
          <Grid2 xs={12} md={6} sx={!Object.keys(fields).length ? { display: "none" } : {}}>
            <PropsPreview>
              {Object.entries(fields).map(([property, value], ind: number) => (
                <Stack key={value + ind}>
                  <Typography variant={"h4"}>{property.replaceAll("_", " ")}:</Typography>
                  <Typography>{value}</Typography>
                  {!value && <Skeleton animation={false} width={Math.random() * 60 + 30 + "%"} />}
                </Stack>
              ))}
            </PropsPreview>
            {!isLoading && !!Object.keys(constantFields).length && Object.keys(fields).length < 3  &&  (
              <Box mt={"3rem"}>
                <AdditionalMetadata data={constantFields} />
              </Box>
            )}
          </Grid2>
          {!isLoading && !!Object.keys(constantFields).length && (Object.keys(fields).length >= 3 || !Object.keys(fields).length) && (
            <Grid2 xs={12} md={6}>
              <AdditionalMetadata data={constantFields} />
            </Grid2>
          )}
        </Grid2>
      </SectionContainer>
      <LastViewContainer>
        <SectionContainer>
          <LastViewTitle>{"Last viewed:"}</LastViewTitle>
        </SectionContainer>
        <Swiper
          loop
          spaceBetween={15}
          slidesPerView={6}
          breakpoints={{
            2200: { slidesPerView: 10 },
            3200: { slidesPerView: 15 },
          }}
          autoplay
          modules={[Autoplay]}
        >
          {list?.map((el, i) => (
            <SwiperSlide key={el.inscription_id + i}>
              <SlideBox onClick={() => chooseLastViewed(el.inscription_id)}>
                {/*{el.thumbnail_data_url ? (*/}
                {/*  <img src={el.thumbnail_data_url} alt={"nft"} />*/}
                {/*) : (*/}
                <PreviewIframe id={el.inscription_id}/>
                <div className={"click-zone"} />
                {/*)}*/}
              </SlideBox>
            </SwiperSlide>
          ))}
        </Swiper>
      </LastViewContainer>
    </>
  );
};

export default InscriptionChecker;
