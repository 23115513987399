import { fetchAddMetadata, fetchCreateInscriptionOrder, fetchGetOrderStatus } from "../../../api/apiRequests";
import { useData } from "../../../utils/useData";
import { useEffect, useRef, useState } from "react";
import validate from "bitcoin-address-validation";
import { useSnack } from "../../snack/useSnack";

export const usePayment = (fee: number, onClose: () => void) => {
  const { userId, picture, picName, metadata } = useData();
  const [step, setStep] = useState(0);
  const [paymentData, setPaymentData] = useState<any>({});
  const snack = useSnack();

  const walletRef: any = useRef();

  const handleApply = async () => {
    const newMetaData: any = {};
    for (const [prop, value] of Object.entries(metadata)) {
      if (value) {
        newMetaData[prop] = value;
      }
    }
    const walletValid = validate(walletRef?.current?.value);

    if (!walletValid) {
      snack("Please provide a valid Bitcoin wallet!", "error");
      return;
    }

    const data = {
      fee: fee,
      uuid: userId,
      data_url: picture,
      user_bitcoin_wallet: walletRef?.current?.value,
      metadata: newMetaData,
    };
    const res = await fetchCreateInscriptionOrder(data);
    setPaymentData(res);
    setStep(1);
    // window.open(res?.coinbase_payment_link, 'payment')
  };

  const getOrderStatus = async () => {
    const res = await fetchGetOrderStatus({ uuid: userId });
    if (res) {
      const currPayment = res.find((el) => el.coinbase_payment_code === paymentData?.coinbase_payment_code);
      if (currPayment?.status === "payment received") {
        onClose();
      }
    }
  };

  useEffect(() => {
    if (!step) return;
    const checkForStatusUpdate = setInterval(async () => {
      await getOrderStatus();
    }, 30000);
    return () => {
      clearInterval(checkForStatusUpdate);
    };
  }, [step]);

  return { walletRef, handleApply, step, paymentData, setStep };
};
