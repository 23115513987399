import axios from "axios";
import apiClient from "./apiClient";
import {
  TBitcoinPrice,
  TGetInscriptionBody,
  TInscription,
  TInscriptionOrderBody,
  TJson,
  TLastViewed,
  TMetadataBody,
  TMetadataData,
  TNotificationBody,
  TOrderStatus,
  TOrderStatusBody,
  TOrderStatusData, TSearchResults,
} from "../types/apiTypes";
import { store } from "../redux/store";

export const fetchAddMetadata = async (data: TMetadataBody) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "metadata") formData.append(key, JSON.stringify(value));
    else formData.append(key, value as string);
  });
  const response = await apiClient.post("", formData, {
    params: {
      operation: "add_meta_data",
    },
  });
  if (response) {
    return response.data as TMetadataData;
  }
};

export const fetchReadMetadata = async (data: TMetadataBody) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "metadata") formData.append(key, JSON.stringify(value));
    else formData.append(key, value as string);
  });
  try {
    const response = await apiClient.post("", formData, {
      params: {
        operation: "read_meta_data",
      },
    });
    if (response) {
      return response.data as TJson;
    }
  } catch (error) {
    return null;
  }
};

export const fetchGetOrderStatus = async (data: TOrderStatusBody) => {
  const id = data?.uuid !== "" ? data.uuid : store.getState().state.userId;
  const formData = new FormData();
  formData.append("uuid", id);
  try {
    const response = await apiClient.post("", formData, {
      params: {
        operation: "order_status",
      },
    });
    if (response?.data) {
      return response.data as TOrderStatus[];
    }
  } catch (error) {
    return null;
  }
};

export const fetchCreateInscriptionOrder = async (data: TInscriptionOrderBody) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "metadata") formData.append(key, JSON.stringify(value));
    else formData.append(key, value as string);
  });
  try {
    const response = await apiClient.post("", formData, {
      params: {
        operation: "create_inscription_order",
      },
    });
    if (response) {
      return response.data as TOrderStatus;
    }
  } catch (error) {
    return null;
  }
};

export const fetchGenerateImage = async (data: TOrderStatusBody) => {
  const formData = new FormData();
  formData.append("uuid", data.uuid);
  const response = await apiClient.post("", formData, {
    params: {
      operation: "generate_image_with_ai",
    },
  });
  if (response?.data) {
    return response.data.image as string;
  }
};

export const fetchEmailNotification = async (data: TNotificationBody) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "metadata") formData.append(key, JSON.stringify(value));
    else formData.append(key, value as string);
  });
  try {
    const response = await apiClient.post("", formData, {
      params: {
        operation: "email_notification",
      },
    });
    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

export const fetchGetInscription = async (data: TGetInscriptionBody) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "metadata") formData.append(key, JSON.stringify(value));
    else formData.append(key, value as string);
  });
  const response = await apiClient.post("", formData, {
    params: {
      operation: "get_inscription",
    },
  });
  if (response?.data) {
    return response.data as TInscription;
  }
};

export const fetchLastViewed = async (data: { uuid: string }) => {
  const formData = new FormData();
  formData.append("uuid", data.uuid);
  try {
    const response = await apiClient.post("", formData, {
      params: {
        operation: "last_viewed",
      },
    });
    if (response?.data) {
      return response.data as TLastViewed[];
    }
  } catch (error) {
    return null;
  }
};
export const fetchCurrentBitcoinPrice = async () => {
  try {
    const response = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd", {
      params: {
        ids: "bitcoin",
        vs_currencies: "usd",
      },
    });
    if (response) {
      return response.data as TBitcoinPrice;
    }
  } catch (error) {
    return null;
  }
};

export const fetchSearchResults = async (query: string) => {

  const uuid = store.getState().state.userId;

  const formData = new FormData();
  formData.append('uuid', uuid)
  formData.append('query', query)
  const response = await apiClient.post("", formData, {
    params: {
      operation: "search",
    },
  });

  return response.data.map((el: TSearchResults) => ({...el, metadata: JSON.parse(el.metadata as any)})) as TSearchResults[];
};
