import {
  Box,
  Button,
  FormControl,
  FormGroup,
  OutlinedInput,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { FlexBox, SectionContainer } from "../../styles/basicLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import theme from "../../styles/theme";
import { mobileScreen, mobileSmallScreen } from "../../styles/breakpoints";
import {LoadingButton} from "@mui/lab";

export const CreateBackground = styled(Box)`
  background-color: #0e0529;
  padding-top: 10.6rem;
`;

export const CreateContainer = styled(SectionContainer)`
  h2 {
    font-family: "F37 Judge", sans-serif;
    font-size: 6.25rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.69px;
    color: #fff;
    text-align: left;
    margin-bottom: 5.7rem;
  }

  > p {
    font-family: "Hellix";
    font-size: 3.25rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.36px;
    color: #807e90;
    text-align: left;
    margin-bottom: 9rem;
  }

  ${mobileScreen} {
    > h2,
    > p {
      text-align: center;
    }

    > p {
      width: 90%;
      margin: 0 auto 9rem;
    }
  }
`;

export const Creator = styled(Grid2)`
  align-items: stretch;
  position: relative;

  > svg {
    position: absolute;
    left: 50%;
    top: 10rem;
    transform: translate(-30%, 0%);

    ${mobileScreen} {
      top: 50%;
      left: 0;
      transform: translate(-30%, -70%) rotate(270deg) scale(0.9);
    }
  }
`;

export const CreatorImage = styled(Stack)`
  height: 100%;
  position: relative;

  > p {
    font-size: 2.37rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.26px;
    text-align: center;
    color: #858395;
    margin: 1.2rem 0 2.4rem;
  }

  .arrow {
    position: absolute;
    left: 94%;
    top: 5%;
    z-index: 1;

    ${mobileScreen} {
      left: -18%;
      top: -15%;
      transform: rotate(270deg);
    }
    ${mobileSmallScreen} {
      left: -18vw;
      top: -15vw;
    }
  }
`;

export const ImageUpload = styled(Box)`
  border: dashed 1.7px #6a49f0;
  border-radius: 2.2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 35rem;
  aspect-ratio: 1/1;
  cursor: pointer;
  
  &.disabled{
    cursor: default;
    
    svg, p{
      opacity: .5;
    }
  }

  svg {
    width: 6rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 3.21rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: 0.36px;
    text-align: center;
    color: #fff;
  }
  
  > img{
    border-radius: 2.2rem;
    width: 100%;
  }
`;

export const GenerateImageButton = styled(LoadingButton)`
  border-radius: 2.2rem;
  box-shadow: 0 26px 28px 0 rgba(14, 3, 43, 0.45);
  background-image: linear-gradient(240deg, #5633f6, #753ab1 25%);
  padding: 3rem;

  font-size: 2.54rem;
  text-transform: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: #fff;

  .MuiButton-startIcon {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const CreatorForm = styled(FormGroup)`
  background-color: #d4d2dc;
  border-radius: 2.2rem;
  box-shadow: 0 3.8rem 3.7rem 0 rgba(20, 8, 47, 0.61);
  padding: 5rem 3.7rem;

  h3 {
    font-size: 3.72rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.41px;
    text-align: center;
    color: #200f55;
    width: 80%;
    margin: 0 auto 4.7rem;
  }

  .MuiInputBase-root {
    flex: 1;
    margin-right: 1rem;
  }

  ${mobileScreen} {
    width: -webkit-fill-available;
    padding: 5rem;
  }
`;

export const CreatorFormControl = styled(FormControl)`
  margin-bottom: 3.4rem;
  .MuiFormLabel-root {
    font-size: 1.8rem;
    font-weight: 600;
    opacity: 0.8;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    color: #200c53;
    
    ${mobileScreen}{
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }

  .trash {
    align-self: center;
    width: 3rem;
    padding: 0.5rem;
    margin-right: -2rem;

    ${mobileScreen}{
      width: 5rem;
      margin-left: 1rem;
    }

    > svg {
      width: 100%;
    }
  }
`;

export const FormInput = styled(OutlinedInput)`
  border-radius: 1.54rem;
  box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #efedf7;
  z-index: 1;
  padding-left: 0.5rem;

  font-size: 2.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: 0.25px;
  color: #5b5374;

  fieldset {
    border: none;
  }

  ${mobileScreen} {
    font-size: 2.9rem;
  }
`;

export const AddFieldButton = styled(Button)`
  font-size: 2.54rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: center;
  color: #351372;

  .MuiButton-startIcon {
    width: 3.7rem;
    height: 3.7rem;
  }
`;
