import {FooterButtonSupport, FooterContainer, FooterCreateButton, FooterSupportSvg, FooterText} from "./Footer.styles";
import {SupportSvg} from "../../assets/svg/icons";
import {Link, useLocation} from "react-router-dom";
import {usePage} from "../../utils/usePage";

const Footer = () => {
    const location = useLocation()
    const {mainPage} = usePage()
    return (
        <FooterContainer className={mainPage ? 'main' : ''}>
            <FooterText className={mainPage ? 'main' : ''}>
                {`Digital Artifacts 2023 © All rights reserved`}
            </FooterText>

            {mainPage &&
                <FooterButtonSupport onClick={() => window.open('mailto:support@digital-artifacts.io')}>
                    <FooterSupportSvg>
                        <SupportSvg/>
                    </FooterSupportSvg>
                    <FooterText>
                        {'Support'}
                    </FooterText>
                </FooterButtonSupport>
            }
            {!mainPage &&
                <Link to={'/'}>
                    <FooterCreateButton>
                        {'Create a Digital Artifact'}
                    </FooterCreateButton>
                </Link>
            }
        </FooterContainer>
    );
}

export default Footer;