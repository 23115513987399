import { useRef, useState } from "react";
import { fetchSearchResults } from "../../api/apiRequests";
import { TSearchResults } from "../../types/apiTypes";
import { useSnack } from "../snack/useSnack";

export const useSearch = () => {
  const [searchResults, setSearchResults] = useState<TSearchResults[]>([]);
  const [inputLoading, setInputLoading] = useState(false);
  const [noResultMsg, setNoResultMsg] = useState(false);
  const searchInputRef: any = useRef();
  const snack = useSnack();

  const getSearchResults = async () => {
    if (!searchInputRef?.current?.value) {
      snack("Enter Incription ID or any Metadata in order to search!", "error");
      return;
    }

    setInputLoading(true);
    const res = await fetchSearchResults(searchInputRef?.current?.value ?? ("" as string));
    setSearchResults(res);
    if (!res.length) {
      setNoResultMsg(true);
    } else {
      setNoResultMsg(false);
    }
    setInputLoading(false);
  };

  return { searchInputRef, getSearchResults, searchResults, inputLoading, noResultMsg };
};
