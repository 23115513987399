import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import { CreatorFormControl, FormInput } from "../../create/Create.styles";
import { TransferActionButtons } from "../Transfer.styles";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;
import { CloseButton, FlexBox } from "../../../styles/basicLayout";
import { CrossIcon } from "../../../assets/svg/icons";
import { usePayment } from "./usePayment";
import QRCode from "react-qr-code";
import { WhiteButton } from "../../../styles/buttons";

type TPopupProps = {
  open: boolean;
  close: () => void;
  apply?: (prop: string, value: string) => void;
  fee: number;
  satoshiRate: number;
};
const Payment = ({ open, close, apply, fee, satoshiRate }: TPopupProps) => {
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setStep(0);
    close();
  };


  const { walletRef, handleApply, step, paymentData, setStep } =
    usePayment(fee, onClose);

  const onApply = async () => {
    setLoading(true);
    await handleApply();
    setLoading(false);
    // close();
  };


  if (step === 1) {
    return (
      <Dialog
        open={open}
        PaperProps={{ sx: { borderRadius: "2rem", maxWidth: "unset" } }}
        onClose={onClose}
      >
        <CloseButton onClick={onClose}>
          <CrossIcon />
        </CloseButton>
        <DialogContent>
          <Stack p={"3rem 2.5rem"}>
            <TransferActionButtons sx={{ m: "3rem auto 5rem" }}>
              <LoadingButton
                onClick={() =>
                  window.open(paymentData?.coinbase_payment_link, "coinbase")
                }
              >
                Pay with coinbase
              </LoadingButton>
            </TransferActionButtons>
            <Typography
              sx={{
                color: "rgb(14, 5, 41)",
                fontSize: "2.7rem",
                fontWeight: 600,
                mb: "4rem",
                textAlign: "center",
              }}
            >
              OR <br /> Pay with BTC
            </Typography>

            <FlexBox sx={{ justifyContent: "center" }} mb={"3rem"}>
              <QRCode
                value={`bitcoin:${paymentData?.btc_address}?amount=${paymentData?.btc_total_price}&label=Digital-Artifacts.io&message=none`}
              />
            </FlexBox>
            <Typography
              sx={{
                color: "rgb(14, 5, 41)",
                fontSize: "2.7rem",
                fontWeight: 600,
                mb: "2rem",
                textAlign: "center",
              }}
            >
              Price: {paymentData?.btc_total_price} BTC ={" "}
              {paymentData?.btc_total_price * 100000000 * satoshiRate}$
            </Typography>
            <FlexBox
              flexDirection={{ xs: "column", sm: "row" }}
              rowGap={"5rem"}
            >
              <FormInput
                value={paymentData?.btc_address}
                // disabled
                sx={{ width: "50rem", maxWidth: "80vw" }}
              />
              <WhiteButton
                sx={{ padding: "1.5rem 3rem", ml: "2rem" }}
                onClick={() =>
                  navigator.clipboard.writeText(paymentData?.btc_address)
                }
              >
                Copy
              </WhiteButton>
            </FlexBox>
          </Stack>
          <FlexBox sx={{ justifyContent: "center", mt: "3rem", mb: "3rem" }}>
            <CircularProgress />
          </FlexBox>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { borderRadius: "2rem" } }}
      onClose={close}
    >
      <CloseButton onClick={close}>
        <CrossIcon />
      </CloseButton>
      <DialogContent>
        <Stack p={"3rem 2.5rem"}>
          <CreatorFormControl>
            <FormLabel>
              Enter the BTC wallet to which you would like to receive the Inscription
            </FormLabel>
            <FormInput
              sx={{ mt: "1rem" }}
              placeholder={"Bitcoin wallet"}
              inputProps={{ ref: walletRef }}
            />
          </CreatorFormControl>
          <TransferActionButtons sx={{ mb: 0, mt: "2rem" }}>
            <LoadingButton
              loading={loading}
              onClick={onApply}
              sx={{ width: "100%", padding: "1rem !important" }}
            >
              Inscribe
            </LoadingButton>
          </TransferActionButtons>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Payment;
