import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import { ImgBox, SectionContainer } from "../../styles/basicLayout";
import { hellixBold, hellixMedium } from "../../styles/typography";
import { mobileScreen } from "../../styles/breakpoints";

export const ExplorerTitle = styled(Typography)`
  font-family: "F37 Judge-Medium", "F37 Judge", sans-serif;
  font-size: 7rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: 0.78px;
  color: #351874;

  margin: 7rem 0;

  ${mobileScreen} {
    text-align: center;
  }
`;

export const InscriptionCheckBackground = styled(Box)`
  background-image: linear-gradient(276deg, #3a1a7e, #12033d);
  margin-bottom: 7rem;
`;

export const InscriptionInputBlock = styled(SectionContainer)`
  padding: 7rem 0;
  
  &.wide{
    //max-width: 135rem;
  }

  ${mobileScreen} {
    .MuiFormControl-root {
      > div {
        flex-direction: column;
      }
    }
  }

  label {
    ${hellixMedium}
    opacity: 0.74;
    font-size: 3.3rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: 0.37px;
    color: #fff;
    margin-bottom: 3rem;
    ${mobileScreen} {
      text-align: center;
    }
  }

  > div {
    width: 100%;
    align-items: stretch;
  }

  .MuiInputBase-root {
    flex: 1;
    border-radius: 1.9rem;
    background-color: #fff;
    padding-left: 1rem;

    font-size: 2.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: #847b98;

    ${mobileScreen} {
      width: 100%;
      margin-bottom: 3rem;
    }
  }

  .MuiButtonBase-root {
    border-radius: 1.9rem;
    box-shadow: 0 26px 28px 0 rgba(14, 3, 43, 0.45);
    background-image: linear-gradient(226deg, #5633f6, #753ab1);
    margin-left: 1.7rem;
    padding: 1.7rem 5rem;

    ${hellixMedium}
    font-size: 3.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0.36px;
    color: #fff;

    ${mobileScreen} {
      margin-left: 0;
      width: 100%;
    }
  }
`;

export const ImagePreview = styled(ImgBox)`
  position: relative;
  background-color: #fff;
  border-radius: 1.9rem;
  padding: 0;
  //box-shadow: 0 2.1rem 2.7rem 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;

  &.noPic {
    padding: 10rem;
    border: dashed 2px #d4d2dc;
  }
  
  .MuiCircularProgress-root{
    margin: 10rem auto;
  }
  
  .click-zone{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  >iframe{
    width: 100%;
    //height: 40rem;
    aspect-ratio: 1/1;
    
    ${mobileScreen}{
      aspect-ratio: 1 / 1;
      height: auto;
      max-height: 50rem;
    }
  }
`;

export const PropsPreview = styled(Stack)`
  > div {
    margin-bottom: 1rem;
  }

  h4,
  p {
    ${hellixMedium};
    font-size: 2.6rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: #7d769a;
  }

  h4 {
    text-transform: capitalize;
  }

  p {
    ${hellixBold};
    font-weight: 500;
    color: #7239b5;
  }

  .MuiSkeleton-root {
    height: 6rem;
    background-color: #f1f1f1;
    border-radius: 0.7rem;
  }
`;

export const ConstantFields = styled(Stack)`
  .MuiAccordionSummary-content {
    font-size: 2rem;
    color: #594c7d;
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #7d769a;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 2rem;
    font-weight: 600;
    color: #7239b5;
    word-break: break-all;
    white-space: pre-wrap;
    hyphens: auto;
    margin-bottom: 1.5rem;
  }
`;
