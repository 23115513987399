import { FlexBox, SectionContainer } from "../../styles/basicLayout";
import {
  ExplorerTitle,
  ImagePreview,
  InscriptionCheckBackground,
  InscriptionInputBlock,
  PropsPreview,
} from "../inscriptionCheck/InscriptionChecker.styles";
import { FormControl, FormLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { MagnifyingGlassIcon } from "./Search.icons";
import { SearchButton, SearchCard, SearchContainer, SearchSubscribe, UpdatesBackground } from "./Search.styles";
import Grid2 from "@mui/material/Unstable_Grid2";
import Picture from "../../assets/webp/Picture1.webp";
import { SubscribeForm } from "../transfer/Transfer.styles";
import { FormInput } from "../create/Create.styles";
import { useSearch } from "./useSearch";
import { useNavigate } from "react-router-dom";
import PreviewIframe from "../iFrame/PreviewIframe";

const Search = () => {
  const { getSearchResults, searchInputRef, inputLoading, searchResults, noResultMsg } = useSearch();
  const navigate = useNavigate();

  return (
    <>
      <SectionContainer className={"wide"}>
        <ExplorerTitle>Ordinals SEARCH ENGINE</ExplorerTitle>
      </SectionContainer>
      <InscriptionCheckBackground>
        <InscriptionInputBlock>
          <FormControl>
            <FormLabel>Enter Incription ID or any Metadata</FormLabel>
            <FlexBox>
              <OutlinedInput
                placeholder={"Type here..."}
                inputProps={{ ref: searchInputRef }}
                onKeyDown={(e) => e.key === "Enter" && getSearchResults()}
                // value={inscriptionInput}
                // onChange={(e) => setInscriptionInput(e.target.value)}
              />
              <SearchButton onClick={getSearchResults} loading={inputLoading}>
                <MagnifyingGlassIcon />
                <Typography>Search</Typography>
              </SearchButton>
            </FlexBox>
          </FormControl>
        </InscriptionInputBlock>
      </InscriptionCheckBackground>
      <SectionContainer>
        {!!searchResults.length && (
          <Grid2 container spacing={"2.5rem"} mb={"13rem"}>
            {searchResults.map((ord) => (
              <Grid2 xs={12} md={6} key={ord.inscription_id}>
                <SearchCard onClick={() => navigate(`/explorer/${ord.inscription_id}`)}>
                  <Stack>
                    <Typography variant={"h3"}>{ord.inscription_name}</Typography>
                    <ImagePreview>
                      <PreviewIframe id={ord.inscription_id}/>
                      <div className={'click-zone'}/>
                    </ImagePreview>
                  </Stack>
                  {!!Object.keys(ord.metadata)?.length && typeof ord.metadata === 'object' && (
                    <PropsPreview>
                      {Object.entries(ord.metadata)?.map(([key, value]: any) => (
                        <Stack>
                          <Typography variant={"h4"}>{key}</Typography>
                          <Typography>{value}</Typography>
                        </Stack>
                      ))}
                    </PropsPreview>
                  )}
                </SearchCard>
              </Grid2>
            ))}
          </Grid2>
        )}
        {noResultMsg && (
          <Typography variant={"h3"} fontWeight={"600"} mb={"7rem"} color={"#7239b5"}>
            No matches found!
          </Typography>
        )}

        {!searchResults.length && !noResultMsg && (
          <Typography variant={"h3"} fontWeight={"500"} mb={"7rem"} color={"#524959"} sx={{opacity: .7}}>
            Your results will be displayed here...
          </Typography>
        )}
      </SectionContainer>
      <UpdatesBackground>
        <SearchContainer>
          <Typography variant={"h4"}>Stay in the loop</Typography>
          <Typography>
            Join our mailing list to stay in the loop with our newest feature releases, Ordinals, and tips and tricks for navigating Digital
            Artifacts.
          </Typography>
          <SearchSubscribe>
            <FlexBox>
              <FormInput
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                inputProps={{ placeholder: "Your email address", type: "email" }}
              />
              <LoadingButton
              // onClick={() => getEmailNotification()}
              // loading={loaderEmail}
              >
                Sign up
              </LoadingButton>
            </FlexBox>
          </SearchSubscribe>
        </SearchContainer>
      </UpdatesBackground>
      <SearchContainer p={"8rem 0"}>
        <Typography variant={"h4"}>Digital Artifacts the world’s first and largest!</Typography>
        <Typography>
          The world’s first and largest digital artifacts for crypto collectibles and non-fungible tokens (Ordinals). Create and discover exclusive digital items.
        </Typography>
      </SearchContainer>
    </>
  );
};

export default Search;
