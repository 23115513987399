
type TTransferModes = {type: string, price: number}[]
export const transferModes:TTransferModes = [
    {
        type: "Economy",
        price: 4
    },    {
        type: "Normal",
        price: 6
    },    {
        type: "Express",
        price: 10
    },
]