import {Button, styled, Typography} from "@mui/material";
import {FlexBox, ImgBox} from "../../styles/basicLayout";
import theme from "../../styles/theme";

export const FooterContainer = styled(FlexBox)`
  background-color: unset;
  background-image: linear-gradient(to top, #ECEBF069 10%, #D6D4DE69 64%, #D4D2DC69 91%);
  padding: 4.5rem 5.5rem;
  
  &.main{
    background-color: #0E0529;
    background-image: unset;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;

    &.main{
      flex-direction: column-reverse;

    }
  }
`

export  const FooterText = styled(Typography)`
  font-size: 2.57rem;
  font-weight: 500;
  color: #5D566D;
  letter-spacing: 0.29px;
  line-height: 1.05;
  display: inline-flex;
  align-items: center;
  
  &.main{
    color: #FFFFFF7C;
  }
`

export const FooterSupportSvg = styled(ImgBox)`
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1rem;
`

export const FooterButtonSupport = styled(Button)`
  text-transform: unset;
  ${theme.breakpoints.down('md')} {
    width: 43rem;
    max-width: 90%;
    padding: 2rem 0;
    border-radius: 1.4rem;
    background-color: #1d0b49;
    margin-bottom: 3.5rem;
  }
`

export const FooterCreateButton = styled(Button)`
  font-size: 2.05rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #8866e2;
  text-transform: unset;
  text-decoration: underline;
  ${theme.breakpoints.down('md')} {
    margin-top: 2.5rem;
  }
`