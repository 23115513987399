import axios from "axios";

const baseURL = "https://api.digital-artifacts.io/api/";

const apiClient = axios.create({
    baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
})


export default apiClient;
